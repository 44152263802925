import { useState } from 'react'
import { Outlet } from 'react-router-dom';
import HeaderRC from '@/components/header';
import FooterRC from '@/components/footer';
import '@/pages/style.css';
import { useIntl } from 'react-intl'
import route_map from '../config/route_map';
import { ReactComponent as Svglang } from '@/assets/lang.svg';
import { ReactComponent as Svglogo } from '@/assets/logo.svg';


const langList = [{ id: 'en-US', text: 'English' }, { id: "zh-CN", text: '中文' }, { id: "ms-MY", text: 'Bahasa Melayu' }]
function Layout(props) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const lang = window.localStorage.lang || 'en-US';
  const lang_text = langList.find((item) => item.id == lang);

  const change = (key, item) => () => {
    window.localStorage.lang = item;
    let pathname = window.location.pathname.split('/');
    pathname[1] = item;
    window.location.href = pathname.join('/')
  }
  const show = () => {
    setOpen(true)
  }
  const out = () => {
    setOpen(false)
  }
  const currentChoose = (to) => {
    if (to == '/') {
      return window.location.pathname == to + window.localStorage.lang ? 'on' : '';
    }
    return window.location.pathname.indexOf(to) > -1 ? 'on' : '';
  };
  return (
    <div className='g_header'>
      <div className='logo'><Svglogo /></div>
      <div className='nav'>
        <a href={route_map.index} className={currentChoose(route_map.entry)}>{intl.formatMessage({ id: "home" })}</a>
        <a href={route_map.ad} className={currentChoose(route_map.ad)}>{intl.formatMessage({ id: "ad" })}</a>
        <a href={route_map.media} className={currentChoose(route_map.media)}>{intl.formatMessage({ id: "media" })}</a>
        <a href={route_map.about} className={currentChoose(route_map.about)}>{intl.formatMessage({ id: "about" })}</a>
      </div>
      <div className={open ? 'lang open' : 'lang'} onMouseEnter={show} onMouseLeave={out}>
        <Svglang /> {lang_text?.text}
        <ul className={open ? 'lang_pop open' : 'lang_pop'}>
          {
            langList.map(item => {
              return <li className={item.id == lang ? 'on' : ''} key={item.id} onClick={change('lang', item.id)}>{item.text}</li>
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default (Layout);
