
import { Outlet } from 'react-router-dom';
import HeaderRC from '@/components/header';
import FooterRC from '@/components/footer';
import '@/pages/style.css';

function Layout(props) {
  return (
    <div className='g_layout'>
      <HeaderRC />
      <div className='g_content_box'>
        <div className={'g_content'}>
          <div className={'g_con'}>
            <Outlet />
          </div>
          <FooterRC />
        </div>
      </div>
    </div>
  );
}

export default (Layout);
