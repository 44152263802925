
const local = `/${localStorage.lang}`; // :lang or '' 多语言是否配置在路径中
const route_map = {
  index: `${local}`,
  entry: `/`,
  ad: `${local}/ad`,
  media: `${local}/media`,
  about: `${local}/about`,
}


export default route_map
