
import '@/pages/style.css';
import { useIntl } from 'react-intl';

import { ReactComponent as Svgicon7 } from '@/assets/icon7.svg';
import { ReactComponent as Svgicon8 } from '@/assets/icon8.svg';
import { ReactComponent as Svgicon9 } from '@/assets/icon9.svg';

import { ReactComponent as Svgicon4 } from '@/assets/icon4.svg';
import { ReactComponent as Svgicon5 } from '@/assets/icon5.svg';
import { ReactComponent as Svgicon6 } from '@/assets/icon6.svg';
import { ReactComponent as Svgicon10 } from '@/assets/icon10.svg';
import { ReactComponent as Svgicon11 } from '@/assets/icon11.svg';
import { ReactComponent as Svggoogle3 } from '@/assets/google3.svg';
import { ReactComponent as Svgfacebook } from '@/assets/facebook.svg';
import { ReactComponent as Svgtiktok2 } from '@/assets/tiktok2.svg';

import { ReactComponent as Svgtiktok } from '@/assets/tiktok.svg';
import { ReactComponent as Svggoogle } from '@/assets/google.svg';
import { ReactComponent as Svgmedia } from '@/assets/media.svg';

import { ReactComponent as Svgmedia1 } from '@/assets/media1.svg';
import { ReactComponent as Svgmedia2 } from '@/assets/media2.svg';
import { ReactComponent as Svgmedia3 } from '@/assets/media3.svg';
import { ReactComponent as Svgmedia4 } from '@/assets/media4.svg';
import { ReactComponent as Svgmedia5 } from '@/assets/media5.svg';
import { ReactComponent as Svgmedia6 } from '@/assets/media6.svg';
import { ReactComponent as Svgmedia7 } from '@/assets/media7.svg';
import { ReactComponent as Svgmedia8 } from '@/assets/media8.svg';


function Layout(props) {
  const intl = useIntl()
  return (
    <div className='index'>
      <div className='banner8'>
        <h2>{intl.formatMessage({ id: "media.title" })}</h2>
        <div className='banner8con'>
          <div>
            <span><Svgicon8 /></span>
            <strong>{intl.formatMessage({ id: "media.1.title" })}</strong>
            <p>{intl.formatMessage({ id: "media.1.desc" })}</p>
          </div>
          <div>
            <span><Svgicon7 /></span>
            <strong>{intl.formatMessage({ id: "media.2.title" })}</strong>
            <p>{intl.formatMessage({ id: "media.2.desc" })}</p>
          </div>
          <div>
            <span><Svgicon9 /></span>
            <strong>{intl.formatMessage({ id: "media.3.title" })}</strong>
            <p>{intl.formatMessage({ id: "media.3.desc" })}</p>
          </div>
        </div>
      </div>
      <div className='banner9'>
        <h2>{intl.formatMessage({ id: "media2.title" })}</h2>
        <div className='banner9con'>
          <strong>{intl.formatMessage({ id: "media2.desc" }, { br: (chunks) => <br /> })}</strong>
          <span><Svgicon10 /></span>
          <span><Svgicon6 /></span>
          <span><Svgicon5 /></span>
          <span><Svgfacebook /></span>
          <span><Svgtiktok2 /></span>
          <span><Svgicon11 /></span>
          <span><Svggoogle3 /></span>
        </div>
      </div>
      <div className='banner10'>
        <h2>{intl.formatMessage({ id: "media3.title" })}</h2>
        <div className='banner10con'>
          <div>
            <span><Svgmedia1 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc1" })}</strong>
          </div>
          <div>
            <span><Svgmedia2 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc2" })}</strong>
          </div>
          <div>
            <span><Svgmedia3 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc3" })}</strong>
          </div>
          <div>
            <span><Svgmedia4 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc4" })}</strong>
          </div>
          <div>
            <span><Svgmedia5 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc5" })}</strong>
          </div>
          <div>
            <span><Svgmedia6 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc6" })}</strong>
          </div>
          <div>
            <span><Svgmedia7 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc7" })}</strong>
          </div>
          <div>
            <span><Svgmedia8 /></span>
            <strong>{intl.formatMessage({ id: "media3.desc8" })}</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default (Layout);
