/* eslint-disable import/no-anonymous-default-export */
export default {
  home: 'Laman Utama',
  ad: "Perkhidmatan Pengiklanan Global",
  media: "Pemasaran Media Sosial Global",
  about: "Tentang Kami",
  'index.banner.text': "Penyelesaian pemasaran global eksklusif yang dibuat khusus",
  'index.banner.text1': "Membantu Perniagaan Memperoleh Pelanggan dengan Mudah",
  'index.ad.title': "Penyelesaian sehenti kepada masalah pemasaran perusahaan global",
  'index.ad.8': "Saluran pemasaran tunggal",
  'index.ad.1': "Kos promosi yang tinggi",
  'index.ad.2': "Kesukaran untuk memperoleh trafik yang tepat",
  'index.ad.3': "Perbezaan budaya dan linguistik yang besar",
  'index.ad.4': "Kerumitan peraturan platform",
  'index.ad.5': "Pelaksanaan operasi yang perlahan",
  'index.ad.6': "Pelanggan global tidak jelas",
  'index.ad.7': "Komunikasi tidak berkesan",
  'index.media.title': "Promosi Global Tersuai",
  'index.media1.title': "Pengoptimuman Carian",
  'index.media1.desc': "Alat pengoptimuman enjin carian, dengan memadankan peraturan enjin carian Google, untuk meningkatkan kedudukan semula jadi data trafik tapak",
  'index.media2.title': "Google ADs",
  'index.media2.desc': "Lihat data Google AdWords dengan mudah dan hantar kembali profil pelanggan yang tepat ke platform iklan dalam satu klik",
  'index.media3.title': "TikTok ADs",
  'index.media3.desc': "Melalui perancangan kreatif dan penempatan yang tepat, kami membantu jenama dengan cepat meningkatkan pendedahan dan interaksi pengguna pada platform video pendek paling popular di dunia",
  'index.media4.title': "Pemasaran Media Sosial",
  'index.media4.desc': "Kedudukan promosi sosial syarikat melalui, penyiaran, sembang Messenger, liputan penuh mesej Leads",
  'index.partner.title': "Rakan Koperasi",
  'ad.ad1.title': "Google Perkhidmatan Pengiklanan",
  'ad1.key': "Persediaan Akaun Pengiklanan",
  'ad2.key': "Pemasaran Reka Bentuk Kreatif",
  'ad3.key': "Operasi Pertumbuhan Proprietari",
  'ad4.key': "Perkhidmatan Pengiklanan Berkesan",
  'ad1.title': "Jimatkan 99% masa anda dengan akaun iklan satu klik",
  'ad1.con1': "Akaun Google Ads",
  'ad1.con2': "Persediaan Akaun Pengiklanan",
  'ad1.con3': "Pasang Penjejak Penukaran Google",
  'ad1.con4': "Semakan Kelayakan",
  'ad1.con5': "Penyelenggaraan Keselamatan Akaun",

  'ad2.title': "Beribu-ribu set kapasiti penjanaan bahan, memahami titik pemasaran letupan",
  'ad2.con1': "Penedudukan Jenama",
  'ad2.con2': "Penyesuaian Kreatif",
  'ad2.con3': "Pengeluaran Video",
  'ad2.con4': "Kempen Pengiklanan",
  'ad2.con5': "Strim Langsung Global dengan Barangan",

  'ad3.title': "Operasi pengiklanan pintar penukaran tinggi, pertumbuhan pengiring yang komprehensif",
  'ad3.con1': "Perkhidmatan Operasi N+",
  'ad3.con2': "Atribusi Penukaran",
  'ad3.con3': "Autonomi Akaun Milik Pelanggan",
  'ad3.con4': "Ketelusan Data",
  'ad3.con5': "Penjejakan Keberkesanan",

  'ad4.title': "Sasarkan khalayak anda dengan tepat untuk memaksimumkan ROI",
  'ad4.con1': "Penjejakan Kod",
  'ad4.con2': "Peluasan Pengiklanan",
  'ad4.con3': "Pelaporan Data Mengenai Penunjuk Utama",
  'ad4.con4': "Penyasaran Orang Ramai",
  'ad4.con5': "Kempen Pemasaran Semula",

  'ad.ad2.title': "TikTok Perkhidmatan Pengiklanan",
  'ad.ad2.1.title': "Penjejakan & Analisis",
  'ad.ad2.1.desc': "Kami memastikan bahawa semua data peletakan dan penukaran, analisis, dsb. adalah tepat dan nyata, supaya setiap sen yang anda belanjakan dapat dikesan",

  'ad.ad2.2.title': "Pengiklanan Ketepatan",
  'ad.ad2.2.desc': "Gunakan data sedia ada, trafik tapak web dan alat pemasaran TikTok untuk memilih khalayak yang sangat tepat untuk anda",
  'ad.ad2.3.title': "Pengujian & Pengoptimuman",
  'ad.ad2.3.desc': "Kami menguji khalayak yang berbeza, idea dan bida kreatif, tetapan, dsb. dan mengoptimumkan akaun untuk mencapai hasil yang hebat",
  'ad.ad2.4.title': "Rancangan Pengiklanan",
  'ad.ad2.4.desc': "Pelan pengiklanan dan pemasaran yang disesuaikan mengikut objektif pengiklanan dan pemasaran anda dan laporkannya dalam laporan harian untuk memastikan objektif tercapai",
  'ad.ad2.5.title': "Kendi Pakar",
  'ad.ad2.5.desc': "Pengoptimuman iklan satu lawan satu dengan pengoptimum TikTok yang telah berakar umbi dalam industri selama bertahun-tahun",
  'ad.ad2.6.title': "Penyiasatan & Laporan",
  'ad.ad2.6.desc': "Memahami produk anda, objektif pemasaran, menganalisis khalayak sasaran anda",

  'media.title': "Pemasaran Media Sosial Global",
  'media.1.title': "Khalayak Luas",
  'media.1.desc': "2.8 bilion+ orang aktif, meliputi lebih daripada 167 negara dan wilayah rangkaian sosial dunia, dengan pangkalan khalayak global yang luas",
  'media.2.title': "Pemasaran Ketepatan Kos Rendah",
  'media.2.desc': 'Mencetuskan maklumat sebenar pengguna besar-besaran, menyediakan pengumpulan kos rendah pelanggan untuk pemasaran ketepatan, dan memupuk potensi pemerolehan pelanggan masa hadapan',
  'media.3.title': "Pemasaran Boleh Terkumpul",
  'media.3.desc': 'Setiap peminat yang tertarik boleh disimpan sebagai aset dan mempunyai kesan eksponen, dan setiap kandungan pemasaran yang disediakan dengan baik akan kekal di halaman utama dan terus mempengaruhi peminat baharu dan lama',

  'media2.title': 'Pengkhususan dalam operasi media sosial global',
  'media2.desc': "Liputan platform media sosial global arus perdana{br}Memenuhi keperluan pemasaran media sosial yang diperibadikan jenama",

  'media3.title': "Perkhidmatan operasi media sosial global sehenti",
  'media3.desc1': "Perancangan Acara",
  'media3.desc2': "Penulisan Salin Kreatif",
  'media3.desc3': "Reka Bentuk Seni",
  'media3.desc4': "Penempatan Pengiklanan",
  'media3.desc5': "Interaksi Pengguna",
  'media3.desc6': "Komunikasi Jenama",
  'media3.desc7': "Pertumbuhan Pengguna",
  'media3.desc8': "Analisis Pendapat Awam",

  'about.title': "Membantu Perusahaan China Meluaskan Pasaran Global",
  'about.desc': "Melalui strategi pemasaran tersuai dan pembangunan perniagaan silang budaya, kami membantu syarikat China membina imej jenama yang kukuh dan mencapai pertumbuhan yang mampan dalam pasaran global. Kami memberi tumpuan kepada sokongan menyeluruh daripada analisis pasaran kepada operasi globalisasi untuk membantu syarikat berjaya dalam pasaran luar negara.",

  'about.title2': "Syarikat",
  'about.desc2': "NightShade Tarven, yang diasaskan pada 2024, komited untuk membina platform awan pemasaran pintar terkemuka di dunia, dan juga yang pertama dalam industri melancarkan platform Marketingforce (Marketingforce), memfokuskan pada kecerdasan buatan, data besar, pengkomputeran awan dalam bidang pemasaran digital dan inovasi dan amalan kecerdasan digital perusahaan, untuk perusahaan global menyediakan perisian dan perkhidmatan tenaga pemasaran. Ia kini telah membentuk IaaS (pengkomputeran awan), PaaS (pusat terbuka), platform awan pemasaran pintar AI SaaS All-in-one (termasuk awan pemasaran, awan jualan, awan perniagaan, awan perkhidmatan perusahaan, awan perkhidmatan dan pintar sehenti yang lain. platform awan pemasaran).",

  'index.about.title': "Kekuatan Teras",
  'index.about1.title': "Mahir Dalam Platform Digital Global Utama",
  'index.about1.desc': "Mahir dalam Google, TikTok dan platform pengiklanan arus perdana global yang lain, dapat menyesuaikan strategi pengiklanan yang paling optimum untuk pelanggan",

  'index.about2.title': "Keupayaan Pemasaran Pintar Terkemuka",
  'index.about2.desc': "Menggunakan alat analitis data lanjutan dan algoritma pembelajaran mesin, kami boleh menggali lebih dalam cerapan tingkah laku pengguna dan terus mengoptimumkan hasil pemasaran",

  'index.about3.title': "Penyetempatan Penciptaan Kandungan",
  'index.about3.desc': "Dengan pasukan kreatif berbilang bahasa kami, kami dapat mencipta kandungan asli yang sesuai dengan budaya untuk pasaran berbeza untuk meningkatkan kesan jenama",

  'index.about4.title': "Operasi Media Sosial Komprehensif",
  'index.about4.desc': "Daripada perancangan kandungan kepada pengurusan interaksi, kami menyediakan perkhidmatan operasi media sosial sehenti untuk membantu jenama membina pangkalan peminat setia",
};
