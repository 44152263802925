/* eslint-disable import/no-anonymous-default-export */
export default {
  home: 'Home',
  ad: "Global Advertising Service",
  media: "Global Social Media Marketing",
  about: "About Us",
  'index.banner.text': "Tailor-made exclusive global marketing solutions",
  'index.banner.text1': "Helping Businesses Easily Acquire Customers",
  'index.ad.title': "One-stop solution to the marketing problems of global enterprises",
  'index.ad.8': "Single marketing channel",
  'index.ad.1': "High promotion costs",
  'index.ad.2': "Difficulty in acquiring accurate traffic",
  'index.ad.3': "Large cultural and linguistic differences",
  'index.ad.4': "Complexity of platform rules",
  'index.ad.5': "Slow implementation of operations",
  'index.ad.6': "Global customers unclear",
  'index.ad.7': "Ineffective communication",
  'index.media.title': "Customised Global Promotion",
  'index.media1.title': "Search Optimisation",
  'index.media1.desc': "Search engine optimisation tools, by matching the Google search engine rules, to improve the natural ranking of the site traffic data",
  'index.media2.title': "Google ADs",
  'index.media2.desc': "Conveniently view Google AdWords data and send accurate customer profiles back to the ad platform in one click",
  'index.media3.title': "TikTok ADs",
  'index.media3.desc': "Through creative planning and precise placement, we help brands rapidly increase exposure and user interaction on the world's most popular short video platforms",
  'index.media4.title': "Social Media Marketing",
  'index.media4.desc': "The company's social promotion positions through, posting, Messenger chat, Leads message full coverage",
  'index.partner.title': "Cooperative Partners",
  'ad.ad1.title': "Google Advertising Service",
  'ad1.key': "Advertising Account Setup",
  'ad2.key': "Creative Design Marketing",
  'ad3.key': "Proprietary Growth Operations",
  'ad4.key': "Effective Advertising Service",
  'ad1.title': "Save 99% of your time with one-click ad accounts",
  'ad1.con1': "Google Ads account",
  'ad1.con2': "Advertising Account Setup",
  'ad1.con3': "Install Google Conversion Tracker",
  'ad1.con4': "Qualification Review",
  'ad1.con5': "Account Security Maintenance",

  'ad2.title': "Thousands sets of material generation capacity, grasp the marketing point of explosion",
  'ad2.con1': "Brand Positioning",
  'ad2.con2': "Creative Customisation",
  'ad2.con3': "Video Production",
  'ad2.con4': "Advertising Campaign",
  'ad2.con5': "Global Live Streaming with Goods",

  'ad3.title': "High-conversion intelligent advertising operations, comprehensive escort growth",
  'ad3.con1': "N+ Operation Services",
  'ad3.con2': "Conversion Attribution",
  'ad3.con3': "Account Autonomy Belongs To The Customer",
  'ad3.con4': "Data Transparency",
  'ad3.con5': "Effectiveness Tracking",

  'ad4.title': "Accurately target your audience to maximise ROI",
  'ad4.con1': "Code Tracking",
  'ad4.con2': "Advertising Expansion",
  'ad4.con3': "Data Reporting On Key Indicators",
  'ad4.con4': "Crowd Targeting",
  'ad4.con5': "Remarketing Campaign",

  'ad.ad2.title': "TikTok Advertising Service",
  'ad.ad2.1.title': "Tracking & Analysis",
  'ad.ad2.1.desc': "We ensure that all placement and conversion data, analyses, etc. are accurate and real, so that every cent you spend is traceable",

  'ad.ad2.2.title': "Precision Advertising",
  'ad.ad2.2.desc': "Use existing data, website traffic, and TikTok marketing tools to select an extremely precise audience for you",
  'ad.ad2.3.title': "Testing & Optimisation",
  'ad.ad2.3.desc': "We test different audiences, creative ideas and bids, settings, etc. and optimise the account to achieve great results",
  'ad.ad2.4.title': "Advertising Plan",
  'ad.ad2.4.desc': "Tailor-made advertising and marketing plans according to your advertising and marketing objectives and report them in daily reports to ensure that the objectives are achieved",
  'ad.ad2.5.title': "Specialist Pitcher",
  'ad.ad2.5.desc': "One-on-one ad optimisation with TikTok optimisers who have been rooted in the industry for years",
  'ad.ad2.6.title': "Investigations & Reports",
  'ad.ad2.6.desc': "Understanding your product, marketing objectives, analysing your target audience",

  'media.title': "Global Social Media Marketing",
  'media.1.title': "Wide Audience",
  'media.1.desc': "2.8 billion+ active people, covering more than 167 countries and regions of the world's social networks, with a broad global audience base",
  'media.2.title': "Low-cost Precision Marketing",
  'media.2.desc': 'Precipitate massive user real information, provide low-cost accumulation of customers for precision marketing, and cultivate future customer acquisition potential',
  'media.3.title': "Marketing Can Be Cumulative",
  'media.3.desc': 'Each attracted fan can be deposited as an asset and has an exponential effect, and each well-prepared marketing content will remain on the homepage and continue to influence new and old fans',

  'media2.title': 'Specialising in global social media operations',
  'media2.desc': "Coverage of mainstream global social media platforms{br}Satisfy brands' personalised social media marketing needs",

  'media3.title': "One-stop global social media operation service",
  'media3.desc1': "Event Planning",
  'media3.desc2': "Creative Copywriting",
  'media3.desc3': "Art Design",
  'media3.desc4': "Advertising Placement",
  'media3.desc5': "User Interaction",
  'media3.desc6': "Brand Communication",
  'media3.desc7': "User Growth",
  'media3.desc8': "Public Opinion Analysis",

  'about.title': "Helping Chinese Enterprises to Expand Global Markets",
  'about.desc': "Through customised marketing strategies and cross-cultural business development, we help Chinese companies build a strong brand image and achieve sustained growth in the global market. We focus on all-round support from market analysis to globalisation operations to help companies succeed in overseas markets.",

  'about.title2': "Company",
  'about.desc2': "NightShade Tarven, founded in 2024, is committed to building the world's leading intelligent marketing cloud platform, and is also the first in the industry to launch the Marketingforce (Marketingforce) platform, focusing on artificial intelligence, big data, cloud computing in the field of digital marketing and enterprise digital intelligence innovation and practice, for the global enterprises to provide marketing force software and services. It has now formed IaaS (cloud computing), PaaS (open centre), All-in-one AI SaaS intelligent marketing cloud platform (including marketing cloud, sales cloud, business cloud, enterprise service cloud, service cloud and other one-stop intelligent marketing cloud platform).",

  'index.about.title': "Core Strengths",
  'index.about1.title': "Proficient In Major Global Digital Platforms",
  'index.about1.desc': "Proficient in Google, TikTok and other global mainstream advertising platforms, able to customise the most optimal advertising strategy for clients",

  'index.about2.title': "Leading Intelligent Marketing Capabilities",
  'index.about2.desc': "Using advanced data analytics tools and machine learning algorithms, we can dig deep into user behavioural insights and continuously optimise marketing results",

  'index.about3.title': "Localisation Of Content Creation",
  'index.about3.desc': "With our multilingual creative team, we are able to create culturally appropriate native content for different markets to enhance brand impact",

  'index.about4.title': "Comprehensive Social Media Operations",
  'index.about4.desc': "From content planning to interaction management, we provide one-stop social media operation services to help brands build a loyal fan base",
};
