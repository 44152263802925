/* eslint-disable import/no-anonymous-default-export */
export default {
  home: '首页',
  ad: "海外广告投放",
  media: "海外社媒营销",
  about: "关于我们",
  'index.banner.text': "定制专属海外营销方案",
  'index.banner.text1': "帮助企业轻松获客",
  'index.ad.title': "一站式解决出海企业营销难题",
  'index.ad.8': "营销渠道单一",
  'index.ad.1': "推广成本高",
  'index.ad.2': "精准流量获取难",
  'index.ad.3': "文化语言差异大",
  'index.ad.4': "平台规则复杂",
  'index.ad.5': "业务落地慢",
  'index.ad.6': "海外客户不清晰",
  'index.ad.7': "沟通效率低",
  'index.media.title': "定制化海外推广",
  'index.media1.title': "搜索优化",
  'index.media1.desc': "搜索引擎优化工具，通过匹配谷歌搜索引擎规则，提升网站自然排名流量数据",
  'index.media2.title': "Google ADs",
  'index.media2.desc': "便捷查看谷歌广告投放数据，一键向广告平台回传精准客户画像",
  'index.media3.title': "TikTok ADs",
  'index.media3.desc': "通过创意策划和精准投放，帮助品牌在全球最受欢迎的短视频平台上快速提升曝光和用户互动",
  'index.media4.title': "社媒运营",
  'index.media4.desc': "公司的社交宣传阵地打通,发帖、Messenger聊天、Leads留言全覆盖",
  'index.partner.title': "合作伙伴",
  'ad.ad1.title': "Google 广告投放服务",
  'ad1.key': "广告账户搭建",
  'ad2.key': "创意设计营销",
  'ad3.key': "专属增长运营",
  'ad4.key': "效果广告服务",
  'ad1.title': "广告账户一键搞定，节省 99% 时间",
  'ad1.con1': "Google 广告开户",
  'ad1.con2': "广告账户搭建",
  'ad1.con3': "安装 Google 转换追踪",
  'ad1.con4': "资质审查",
  'ad1.con5': "账户安全维护",

  'ad2.title': "万套素材生成能力，把握营销爆点",
  'ad2.con1': "品牌定位",
  'ad2.con2': "创意定制",
  'ad2.con3': "视频制作",
  'ad2.con4': "广告策划",
  'ad2.con5': "海外直播带货",

  'ad3.title': "高转化智能广告运营，全面护航增长",
  'ad3.con1': "N+ 运营服务",
  'ad3.con2': "转化归因",
  'ad3.con3': "账户自主权归客户所有",
  'ad3.con4': "数据透明",
  'ad3.con5': "效果追踪",

  'ad4.title': "精准定位受众，最大化ROI",
  'ad4.con1': "代码追踪",
  'ad4.con2': "广告拓量",
  'ad4.con3': "关键指标数据报告",
  'ad4.con4': "人群定向",
  'ad4.con5': "再营销广告",

  'ad.ad2.title': "TikTok 广告投放服务",
  'ad.ad2.1.title': "跟踪&分析",
  'ad.ad2.1.desc': "我们确保所有的投放与转化数据、分析等都准确真实，让您花的每分钱都有迹可循",

  'ad.ad2.2.title': "精准投流",
  'ad.ad2.2.desc': "通过现有数据、网站流量、以及TikTok营销工具，来为您挑选极精准匹配的受众",
  'ad.ad2.3.title': "测试&优化",
  'ad.ad2.3.desc': "我们会反复测试不同人群受众、创意和出价、设置等，并不断优化账户以达极佳效果",
  'ad.ad2.4.title': "广告计划",
  'ad.ad2.4.desc': "根据您的广告营销目的，量身定制广告营销计划并以日报的形势汇报，以确保达成营销目的",
  'ad.ad2.5.title': "专业投手",
  'ad.ad2.5.desc': "植根行业多年的TikTok优化师为您提供全程一对一广告优化服务",
  'ad.ad2.6.title': "调查&报告",
  'ad.ad2.6.desc': "了解您的产品、营销目的、分析目标受众",

  'media.title': "海外社交媒体推广",
  'media.1.title': "受众广泛",
  'media.1.desc': '活跃人数28亿+，覆盖全球超过167个国家和地区的社交网络，全球受众基础广泛',
  'media.2.title': "低成本精准营销",
  'media.2.desc': '沉淀海量用户真实信息，为精准营销提供低成本积累客户，培育未来获客潜力',
  'media.3.title': "营销可积累",
  'media.3.desc': '每一个被吸引来的粉丝都可作为资产沉淀下来并且具备指数效应，每一次精心准备的营销内容都会留存在主页，持续影响新老粉丝',

  'media2.title': '专注海外社交媒体运营',
  'media2.desc': "覆盖海外主流社交媒体平台{br}满足品牌个性化社媒营销需求",

  'media3.title': "一站式海外社媒运营服务",
  'media3.desc1': "活动策划",
  'media3.desc2': "创意文案",
  'media3.desc3': "美术设计",
  'media3.desc4': "广告投放",
  'media3.desc5': "用户互动",
  'media3.desc6': "品牌传播",
  'media3.desc7': "用户增长",
  'media3.desc8': "舆情分析",

  'about.title': "助力中国企业出海拓展全球市场",
  'about.desc': "通过定制化的营销策略和跨文化业务拓展，帮助中国企业在全球市场建立强大的品牌形象，实现持续增长。我们专注于从市场分析到全球化运营的全方位支持，助力企业在海外市场取得成功。",

  'about.title2': "公司介绍",
  'about.desc2': "NightShade Tarven，成立于2024年，致力于打造全球领先的智能营销云平台，也是行业内率先推出的Marketingforce（营销力）平台，专注于人工智能、大数据、云计算在数字营销及企业数字化智能化领域的创新与实践，面向全球企业提供营销力软件及服务，现已形成IaaS（云计算）、PaaS（开放中台）、All-in-one AI SaaS智能营销云平台（含营销云、销售云、商业云、企服云、服务云等一站式智能营销云平台）。",

  'index.about.title': "核心优势",
  'index.about1.title': "精通全球主流数字平台",
  'index.about1.desc': "精通Google、TikTok等全球主流广告平台,能够为客户量身定制最优化的广告投放策略",

  'index.about2.title': "领先的智能营销能力",
  'index.about2.desc': "运用先进的数据分析工具和机器学习算法,我们能够深入挖掘用户行为洞察,持续优化营销效果",

  'index.about3.title': "本土化内容创作",
  'index.about3.desc': "我们拥有多语种创意团队,能够为不同市场创作符合当地文化的原生内容,提升品牌影响力",

  'index.about4.title': "全面的社交媒体运营",
  'index.about4.desc': "从内容策划到互动管理,我们提供一站式社交媒体代运营服务,助力品牌建立忠实粉丝群体",
};
