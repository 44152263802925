
import '@/pages/style.css';
import { useIntl } from 'react-intl';
import banner from '@/assets/banner.png'
import { ReactComponent as Svgsearch } from '@/assets/index.3.1.svg';
import { ReactComponent as Svggoogle } from '@/assets/google.svg';
import { ReactComponent as Svgtiktok } from '@/assets/tiktok.svg';
import { ReactComponent as Svgmedia } from '@/assets/media.svg';

import { ReactComponent as Svggoogle2 } from '@/assets/google2.svg';
import { ReactComponent as Svgfacebook } from '@/assets/facebook.svg';
import { ReactComponent as Svgtiktok2 } from '@/assets/tiktok2.svg';

import { ReactComponent as Svgright } from '@/assets/right.svg';

import { ReactComponent as Svg24 } from '@/assets/24.svg';
import { ReactComponent as Svg25 } from '@/assets/25.svg';
import { ReactComponent as Svg26 } from '@/assets/26.svg';
import { ReactComponent as Svg27 } from '@/assets/27.svg';
import { ReactComponent as Svg28 } from '@/assets/28.svg';
import { ReactComponent as Svg29 } from '@/assets/29.svg';

import ad1 from '@/assets/ad1.png';
import ad2 from '@/assets/ad2.png';
import ad3 from '@/assets/ad3.png';
import ad4 from '@/assets/ad4.png';

import { useState } from 'react'

function Layout(props) {
  const intl = useIntl()
  const [index, setIndex] = useState(0);
  const ads = [
    {
      key: intl.formatMessage({ id: "ad1.key" }),
      img: ad1,
      title: intl.formatMessage({ id: "ad1.title" }),
      desc: [
        intl.formatMessage({ id: "ad1.con1" }),
        intl.formatMessage({ id: "ad1.con2" }),
        intl.formatMessage({ id: "ad1.con3" }),
        intl.formatMessage({ id: "ad1.con4" }),
        intl.formatMessage({ id: "ad1.con5" })
      ]
    },
    {
      key: intl.formatMessage({ id: "ad2.key" }),
      img: ad2,
      title: intl.formatMessage({ id: "ad2.title" }),
      desc: [
        intl.formatMessage({ id: "ad2.con1" }),
        intl.formatMessage({ id: "ad2.con2" }),
        intl.formatMessage({ id: "ad2.con3" }),
        intl.formatMessage({ id: "ad2.con4" }),
        intl.formatMessage({ id: "ad2.con5" })
      ]
    },
    {
      key: intl.formatMessage({ id: "ad3.key" }),
      img: ad3,
      title: intl.formatMessage({ id: "ad3.title" }),
      desc: [
        intl.formatMessage({ id: "ad3.con1" }),
        intl.formatMessage({ id: "ad3.con2" }),
        intl.formatMessage({ id: "ad3.con3" }),
        intl.formatMessage({ id: "ad3.con4" }),
        intl.formatMessage({ id: "ad3.con5" })
      ]
    },
    {
      key: intl.formatMessage({ id: "ad4.key" }),
      img: ad4,
      title: intl.formatMessage({ id: "ad4.title" }),
      desc: [
        intl.formatMessage({ id: "ad4.con1" }),
        intl.formatMessage({ id: "ad4.con2" }),
        intl.formatMessage({ id: "ad4.con3" }),
        intl.formatMessage({ id: "ad4.con4" }),
        intl.formatMessage({ id: "ad4.con5" })
      ]
    },
  ]
  return (
    <div className='index'>
      <div className='g-banner banner5'>
        <h2>{intl.formatMessage({ id: "ad.ad1.title" })}</h2>
        <div className='adscon'>
          <div className='adscon1'>
            {
              ads.map((item, i) => {
                return <span onClick={() => setIndex(i)} className={index == i ? 'on' : ''} >{item.key}</span>
              })
            }
          </div>
          <div className='adscon2'>
            <img src={ads[index].img} />
          </div>
          <div className='adscon3'>
            <h3>{ads[index].title}</h3>
            {
              ads[index].desc.map(item => {
                return <p><Svgright /> <span>{item}</span></p>
              })
            }
          </div>
        </div>
      </div>
      <div className='g-banner banner6'>
        <h2>{intl.formatMessage({ id: "ad.ad2.title" })}</h2>
        <div className='banner6bg'></div>
        <div className='banner6con'>
          <div>
            <span><Svg24 /></span>
            <strong>{intl.formatMessage({ id: "ad.ad2.1.title" })}</strong>
            <p>{intl.formatMessage({ id: "ad.ad2.1.desc" })}</p>
          </div>
          <div>
            <span><Svg25 /></span>
            <strong>{intl.formatMessage({ id: "ad.ad2.2.title" })}</strong>
            <p>{intl.formatMessage({ id: "ad.ad2.2.desc" })}</p>
          </div>
          <div>
            <span><Svg26 /></span>
            <strong>{intl.formatMessage({ id: "ad.ad2.3.title" })}</strong>
            <p>{intl.formatMessage({ id: "ad.ad2.3.desc" })}</p>
          </div>
          <div>
            <span><Svg27 /></span>
            <strong>{intl.formatMessage({ id: "ad.ad2.4.title" })}</strong>
            <p>{intl.formatMessage({ id: "ad.ad2.4.desc" })}</p>
          </div>
          <div>
            <span><Svg28 /></span>
            <strong>{intl.formatMessage({ id: "ad.ad2.5.title" })}</strong>
            <p>{intl.formatMessage({ id: "ad.ad2.5.desc" })}</p>
          </div>
          <div>
            <span><Svg29 /></span>
            <strong>{intl.formatMessage({ id: "ad.ad2.6.title" })}</strong>
            <p>{intl.formatMessage({ id: "ad.ad2.6.desc" })}</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default (Layout);
