import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
} from "react-router-dom";
import router from './router';
import PageLoading from '@/components/pageLoading';
import { IntlProvider } from 'react-intl'
import zhCN from "@/locales/zh-cn";
import enUS from "@/locales/en-us";
import msMY from '@/locales/ms-my';

const langmap = {
  "EN-US": enUS,
  "ZH-CN": zhCN,
  'MS-MY': msMY
};
function browserLang() {
  var ls = navigator.languages ? navigator.languages.length : 0;
  var res = ls
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage;
  // es, es-us, es-mx,es-gt等等西班牙语，统一使用 es
  if (/^es-?/.test(res)) {
    res = "es-es";
  }
  return res;
}
const lang = window.localStorage.lang || browserLang() || "en-US";
const result = langmap[lang.toUpperCase()] || enUS;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <IntlProvider
    locale={result.locale}
    messages={result.messages}
    formats={result.formats}
    onError={info => { }}>
    <RouterProvider router={router} fallbackElement={<PageLoading />} />
  </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
