
import { Outlet } from 'react-router-dom';
import '@/pages/style.css';

function Layout(props) {
  return (
    <div className='index'>
      loading
    </div>
  );
}

export default (Layout);
