import {
  createBrowserRouter,
} from "react-router-dom";
import route_map from "@/config/route_map";
import IndexRC from '@/pages/index';
import LayoutRC from '@/components/layout'
import Ad from '@/pages/ad';
import Media from '@/pages/media';
import AboutUS from '@/pages/about';
import ErrorBoundary from '@/components/ErrorBoundary.js'

const router = createBrowserRouter([
  {
    path: route_map.entry,
    element: <LayoutRC />,
    children: [
      {
        path: '/en-US',
        element: <IndexRC />
      },
      {
        path: '/zh-CN',
        element: <IndexRC />
      },
      {
        path: route_map.index,
        element: <IndexRC />
      },
      {
        path: route_map.ad,
        element: <Ad />
      },
      {
        path: route_map.media,
        element: <Media />
      },
      {
        path: route_map.about,
        element: <AboutUS />
      },
    ],
    errorElement: <ErrorBoundary />
  },


])

export default router