import { isRouteErrorResponse } from "react-router-dom";
import { useRouteError } from 'react-router-dom'
import route_map from "../config/route_map";
function ErrorBoundary() {
  const error = useRouteError();
  if (error.status == 404) {
    window.location.href = route_map.index;
    return;
  }
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>Oops!</h1>
        <h2>{error.status}</h2>
        <p>{error.statusText}</p>
        {error.data?.message && <p>{error.data.message}</p>}
      </div>
    );
  } else {
    return <div>404</div>;
  }
}

export default ErrorBoundary