
import { Outlet } from 'react-router-dom';
import HeaderRC from '@/components/header';
import FooterRC from '@/components/footer';
import { ReactComponent as Svgemail } from '@/assets/Email.svg';
import { ReactComponent as Svgtele } from '@/assets/Telegram.svg';

import '@/pages/style.css';

function Layout(props) {
  return (
    <div className='g_footer'>
      <div>
        ©2024 nsmkt.net
      </div>
      <ul>
        <li><a href="mailto:info@nsmkt.net"><Svgemail /></a></li>
        <li><a href="https://t.me/nightshade_mkt" target='_blank' rel="noreferrer"><Svgtele /></a></li>
      </ul>
    </div>
  );
}

export default (Layout);
