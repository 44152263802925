
import '@/pages/style.css';
import { useIntl } from 'react-intl';
import banner from '@/assets/banner.png'
import { ReactComponent as Svgsearch } from '@/assets/index.3.1.svg';
import { ReactComponent as Svggoogle } from '@/assets/google.svg';
import { ReactComponent as Svgtiktok } from '@/assets/tiktok.svg';
import { ReactComponent as Svgmedia } from '@/assets/media.svg';

import { ReactComponent as Svg30 } from '@/assets/30.svg';
import { ReactComponent as Svg31 } from '@/assets/31.svg';
import { ReactComponent as Svg33 } from '@/assets/33.svg';
import { ReactComponent as Svg34 } from '@/assets/34.svg';

function Layout(props) {
  const intl = useIntl()
  const lang = window.localStorage.lang || 'en-US';
  return (
    <div className='index'>
      <div className='banner7'>
        <div className='banner7-1'>
          <h1>{intl.formatMessage({ id: "about.title" })}</h1>
          <p>{intl.formatMessage({ id: "about.desc" })}</p>
        </div>
        <div className='banner7-2'>
          <h2>{intl.formatMessage({ id: "about.title2" })}</h2>
          <p>{intl.formatMessage({ id: "about.desc2" })}</p>
        </div>
      </div>

      <div className='banner3'>
        <h2>{intl.formatMessage({ id: "index.about.title" })}</h2>
        <div className='banner3con'>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svg34 /></span>
            <strong>{intl.formatMessage({ id: "index.about1.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.about1.desc" })}</p>
          </div>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svg31 /></span>
            <strong>{intl.formatMessage({ id: "index.about2.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.about2.desc" })}</p>
          </div>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svg30 /></span>
            <strong>{intl.formatMessage({ id: "index.about3.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.about3.desc" })}</p>
          </div>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svg33 /></span>
            <strong>{intl.formatMessage({ id: "index.about4.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.about4.desc" })}</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default (Layout);
