
import '@/pages/style.css';
import { useIntl } from 'react-intl';
import banner from '@/assets/banner.png'
import { ReactComponent as Svgsearch } from '@/assets/index.3.1.svg';
import { ReactComponent as Svggoogle } from '@/assets/google.svg';
import { ReactComponent as Svgtiktok } from '@/assets/tiktok.svg';
import { ReactComponent as Svgmedia } from '@/assets/media.svg';

import { ReactComponent as Svggoogle2 } from '@/assets/google2.svg';
import { ReactComponent as Svgfacebook } from '@/assets/facebook.svg';
import { ReactComponent as Svgtiktok2 } from '@/assets/tiktok2.svg';

function Layout(props) {
  const intl = useIntl()
  const lang = window.localStorage.lang || 'en-US';
  return (
    <div className='index'>
      <div className='banner'>
        <h1><p>{intl.formatMessage({ id: "index.banner.text" })}</p><span style={{ top: 40, left: 160 }}></span>
          <p>{intl.formatMessage({ id: "index.banner.text1" })}</p><span style={{ top: 110, left: 200 }}></span></h1>
        <img src={banner} />
      </div>
      <div className='banner2'>
        <h2>{intl.formatMessage({ id: "index.ad.title" })}</h2>
        <div>
          <span>{intl.formatMessage({ id: "index.ad.1" })}</span>
          <span>{intl.formatMessage({ id: "index.ad.2" })}</span>
          <span>{intl.formatMessage({ id: "index.ad.3" })}</span>
          <span>{intl.formatMessage({ id: "index.ad.4" })}</span>
          <span>{intl.formatMessage({ id: "index.ad.5" })}</span>
          <span>{intl.formatMessage({ id: "index.ad.6" })}</span>
          <span>{intl.formatMessage({ id: "index.ad.7" })}</span>
          <span>{intl.formatMessage({ id: "index.ad.8" })}</span>
        </div>
      </div>
      <div className='banner3'>
        <h2>{intl.formatMessage({ id: "index.media.title" })}</h2>
        <div className={'banner3con'}>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svgsearch /></span>
            <strong>{intl.formatMessage({ id: "index.media1.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.media1.desc" })}</p>
          </div>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svggoogle /></span>
            <strong>{intl.formatMessage({ id: "index.media2.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.media2.desc" })}</p>
          </div>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svgtiktok /></span>
            <strong>{intl.formatMessage({ id: "index.media3.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.media3.desc" })}</p>
          </div>
          <div style={lang == 'zh-CN' ? { height: 360 } : {}}>
            <span><Svgmedia /></span>
            <strong>{intl.formatMessage({ id: "index.media4.title" })}</strong>
            <p>{intl.formatMessage({ id: "index.media4.desc" })}</p>
          </div>
        </div>
      </div>
      <div className='banner4'>
        <h2>{intl.formatMessage({ id: "index.partner.title" })}</h2>
        <div className='banner4con'>
          <div>
            <span><Svggoogle2 /></span>
            <strong>Google</strong>
          </div>
          <div>
            <span><Svgfacebook /></span>
            <strong>Facebook</strong>
          </div>
          <div>
            <span><Svgtiktok2 /></span>
            <strong>Tiktok</strong>
          </div>
        </div>
      </div>
    </div >
  );
}

export default (Layout);
