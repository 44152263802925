// 引入组件的多语言
// import componentsMsg from "../lib/locales/zh-cn.message";
import msg from "./zh-cn.message";
const appLocale = {
  // 合并所有 messages, 加入组件的 messages
  messages: Object.assign({}, msg),

  // locale
  locale: "zh-CN",

  // react-intl locale-data

  // 自定义 formates
  formats: {
    // 日期、时间
    date: {
      normal: {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
    },
    // 货币
    money: {
      currency: "CNY",
    },
  },
};

export default appLocale;
