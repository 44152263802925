// 引入组件的多语言
// import componentsMsg from "../lib/locales/en-us.message";

import msg from "./ms-my.message";

const appLocale = {
  // 合并所有 messages, 加入组件的 messages
  messages: Object.assign({}, msg),

  // locale
  locale: "ms-MY",

  // react-intl locale-data

  // 自定义 formates
  formats: {
    date: {
      normal: {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
    },
    // 货币
    money: {
      currency: "USD",
    },
  },
};

export default appLocale;
